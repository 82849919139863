import styled from 'styled-components';
import React, { useEffect, useCallback, useMemo } from 'react';
import { Accordion, Card, Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { push, goBack } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { setModalConfirmationMetaAction, setInfoToastAction, setLayoutAction } from 'modules/layouts';
import {
  fetchTaskChecklistAction,
  updateTaskChecklistAction,
  deleteTaskAttachmentsAction,
  fetchTaskDetailsAction,
  deleteTaskAction,
  setTaskDetailsAction,
} from 'modules/tasks';
import { taskDetailsSelector } from 'modules/tasks/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { TaskDetailsMeta, TaskDeleteAlert } from 'components';
import { TasksHardwareTable } from 'components/_tables';
import { TasksModal } from 'components/_modals';
import {
  Tabs,
  Tab,
  Button,
  BackToTableButton,
  DataTableTemplate,
  Checklist,
  Gallery,
  OverlayTriggerTooltip,
  Spinner,
} from 'components/_common';
import TaskDetailsComments from './TaskDetailsComments';
import { getGalleryImages } from 'utils';
import { Routes, TaskStatus, BuilderFormTypeNames } from 'constants/index';
import { IconEdit, IconDelete, IconDetails } from '@utiligize/shared/resources';

const TaskDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {
  const id: number = Number(match.params.id);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, lng } = useLocale();
  const task = useSelector(taskDetailsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const simulationVersionId = useSelector(simulationSelectedOptionVersionIdSelector);

  useEffect(() => {
    if (!portfolioId || !simulationVersionId) return;
    // fetch task details and redirect to table in case of network error
    dispatch(fetchTaskDetailsAction({ id, portfolioId, simulationVersionId })).catch(() =>
      dispatch(push(generatePath(Routes.Tasks)))
    );
    // clean task details on view unmount
    return () => {
      dispatch(setTaskDetailsAction(null));
    };
  }, [id, portfolioId, simulationVersionId, dispatch]);

  const handleDeleteBtnClick = useCallback(() => {
    dispatch(
      setModalConfirmationMetaAction({
        bodyComponent: <TaskDeleteAlert name={task?.name || ''} />,
        onConfirm: () => dispatch(deleteTaskAction(id)).then(() => dispatch(push(generatePath(Routes.Tasks)))),
      })
    );
  }, [dispatch, id, task]);

  const handleEditBtnClick = useCallback(() => dispatch(setLayoutAction({ taskModalMeta: { id } })), [dispatch, id]);

  const { length } = history;
  const handleBackBtnClick = useCallback(
    (event: React.MouseEvent) => {
      if (length <= 2) return;
      event.preventDefault();
      dispatch(goBack());
    },
    [length, dispatch]
  );

  const renderAccordionToggle = ({
    eventKey,
    titleKey,
    title,
    length,
  }: {
    eventKey: string;
    titleKey?: string;
    title?: string;
    length: number;
  }) => {
    const text = `${title || (titleKey && getIntl(titleKey))}: ${length}`;
    return length ? (
      <Accordion.Toggle as={Card.Header} eventKey={eventKey} className="d-flex align-items-center cursor-pointer">
        {text} <i className="fas fa-chevron-down ml-auto" />
      </Accordion.Toggle>
    ) : (
      <Card.Header>{text}</Card.Header>
    );
  };

  const {
    taskCompletionsFinalChecks,
    taskCompletionsInspections,
    taskCompletionsRelatedForms,
    taskCompletionsOther,
    images,
  } = useMemo(() => {
    const init = {
      taskCompletionsFinalChecks: [],
      taskCompletionsInspections: [],
      taskCompletionsRelatedForms: [],
      taskCompletionsOther: [],
      images: [],
    };
    if (!task) return init;
    return task.taskCompletions.reduce(
      (
        acc: {
          taskCompletionsFinalChecks: Tasks.TaskCompletion[];
          taskCompletionsInspections: Tasks.TaskCompletion[];
          taskCompletionsRelatedForms: Tasks.TaskCompletion[];
          taskCompletionsOther: Tasks.TaskCompletion[];
          images: Type.GalleryImage[];
        },
        item: Tasks.TaskCompletion
      ) => {
        if (item.answer?.answerAttachments?.length) {
          acc.images.push(...getGalleryImages(item.answer?.answerAttachments));
        }

        if ([BuilderFormTypeNames.FinalCheck].includes(item.answer?.formTypeName as Builder.FormTypeNames)) {
          acc.taskCompletionsFinalChecks.push(item);
        } else if (
          [BuilderFormTypeNames.TaskRelatedForm].includes(item.answer?.formTypeName as Builder.FormTypeNames)
        ) {
          acc.taskCompletionsRelatedForms.push(item);
        } else if ([BuilderFormTypeNames.Inspection].includes(item.answer?.formTypeName as Builder.FormTypeNames)) {
          acc.taskCompletionsInspections.push(item);
        } else {
          acc.taskCompletionsOther.push(item);
        }
        return acc;
      },
      init
    );
    // Update photo tags on language change
  }, [task, lng]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCompletion = (index: number, completion: Tasks.TaskCompletion) => {
    const assetCode = completion.answer?.assetNumber;
    const assetUuid = completion.answer?.assetUuid;
    return (
      <li key={index} className="d-flex align-items-center justify-content-between">
        <span className="text">
          {completion.date}
          {Boolean(assetCode) && (
            <>
              {' - '}
              {assetUuid ? (
                <Link
                  to={{
                    pathname: generatePath(Routes.Map, { uuid: assetUuid }),
                    state: { prevRoute: window.location.pathname },
                  }}
                >
                  {assetCode}
                </Link>
              ) : (
                assetCode
              )}
            </>
          )}
          {' - '}
          {completion.user.fullName} - {completion.user.email}
          {completion.notActual && (
            <>
              {' '}
              <Badge variant="warning" className="m-0">
                {getIntl('Not actual')}
              </Badge>
            </>
          )}
        </span>
        {completion.answer?.id && (
          <Link
            to={{
              pathname: generatePath(Routes.HistoryAnswers, { id: completion.answer.id }),
              state: { prevRoute: window.location.pathname },
            }}
          >
            <OverlayTriggerTooltip
              placement="top"
              overlayId={String(index)}
              overlayChildren={getIntl('Answer details')}
            >
              <Button icon={<IconDetails />} />
            </OverlayTriggerTooltip>
          </Link>
        )}
      </li>
    );
  };

  const handleSelectImage = useCallback(
    (index: number, image: Type.GalleryImage) => {
      const nextUsersAttachments = task!.usersAttachments.slice();
      nextUsersAttachments[index].isSelected = !image.isSelected;
      dispatch(setTaskDetailsAction({ ...task!, usersAttachments: nextUsersAttachments }));
    },
    [dispatch, task]
  );

  const handleDeleteButtonClick = useCallback(() => {
    const { ids, nextImages } = task!.usersAttachments.reduce(
      (acc: { ids: number[]; nextImages: Type.GalleryImage[] }, image: Type.GalleryImage) => {
        if (image.isSelected) {
          acc.ids.push(image.id);
        } else {
          acc.nextImages.push(image);
        }
        return acc;
      },
      { ids: [], nextImages: [] }
    );

    if (!ids.length) return dispatch(setInfoToastAction('Please select at least one photo'));

    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Do you want to delete selected photos?',
        onConfirm: async () =>
          dispatch(deleteTaskAttachmentsAction(id, ids)).then(() =>
            dispatch(setTaskDetailsAction({ ...task!, usersAttachments: nextImages }))
          ),
      })
    );
  }, [id, task, dispatch]);

  return (
    <>
      <DataTableTemplate title={`${getIntl('Task')} / ${id}`} paddingBottom>
        <div className="d-flex justify-content-between">
          <Link to={generatePath(Routes.Tasks)} onClick={handleBackBtnClick}>
            <BackToTableButton />
          </Link>
          <div>
            <Button
              className="mr-2"
              icon={<IconEdit />}
              data-id={id}
              marginLeft
              onClick={handleEditBtnClick}
              aria-label="Edit task"
            />
            <Button icon={<IconDelete />} onClick={handleDeleteBtnClick} />
          </div>
        </div>
        <StyledContainer>
          <div>
            {!task ? (
              <Spinner isInFullHeightContainer />
            ) : (
              <>
                <Row className="justify-content-md-between mb-5">
                  <Col md={6}>
                    <Checklist<Tasks.ChecklistAnswer>
                      isTaskChecklist
                      id={id}
                      fetchChecklistAction={fetchTaskChecklistAction}
                      updateChecklistAction={updateTaskChecklistAction}
                      actionDisabledMessage={
                        task.status !== TaskStatus.Finished ? "You can't update checklist for unfinished task" : ''
                      }
                    />

                    <Accordion className="mt-5">
                      <Card className="mb-0">
                        {renderAccordionToggle({
                          eventKey: 'other-completions',
                          titleKey: 'Task completions',
                          length: taskCompletionsOther.length,
                        })}
                        <Accordion.Collapse eventKey="other-completions">
                          <ul className="todo-list">
                            {taskCompletionsOther.map((completion, index) => renderCompletion(index, completion))}
                          </ul>
                        </Accordion.Collapse>
                      </Card>

                      {Boolean(taskCompletionsFinalChecks.length) && (
                        <Card className="mb-0">
                          {renderAccordionToggle({
                            eventKey: BuilderFormTypeNames.FinalCheck,
                            titleKey: BuilderFormTypeNames.FinalCheck,
                            length: taskCompletionsFinalChecks.length,
                          })}
                          <Accordion.Collapse eventKey={BuilderFormTypeNames.FinalCheck}>
                            <ul className="todo-list">
                              {taskCompletionsFinalChecks.map((completion, index) =>
                                renderCompletion(index, completion)
                              )}
                            </ul>
                          </Accordion.Collapse>
                        </Card>
                      )}
                      {Boolean(taskCompletionsRelatedForms.length) && (
                        <Card className="mb-0">
                          {renderAccordionToggle({
                            eventKey: BuilderFormTypeNames.TaskRelatedForm,
                            titleKey: BuilderFormTypeNames.TaskRelatedForm,
                            length: taskCompletionsRelatedForms.length,
                          })}
                          <Accordion.Collapse eventKey={BuilderFormTypeNames.TaskRelatedForm}>
                            <ul className="todo-list">
                              {taskCompletionsRelatedForms.map((completion, index) =>
                                renderCompletion(index, completion)
                              )}
                            </ul>
                          </Accordion.Collapse>
                        </Card>
                      )}
                      {Boolean(taskCompletionsInspections.length) && (
                        <Card className="mb-0">
                          {renderAccordionToggle({
                            eventKey: BuilderFormTypeNames.Inspection,
                            titleKey: BuilderFormTypeNames.Inspection,
                            length: taskCompletionsInspections.length,
                          })}
                          <Accordion.Collapse eventKey={BuilderFormTypeNames.Inspection}>
                            <ul className="todo-list">
                              {taskCompletionsInspections.map((completion, index) =>
                                renderCompletion(index, completion)
                              )}
                            </ul>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      <Card className="mb-0">
                        {renderAccordionToggle({
                          eventKey: 'uploaded-documents',
                          titleKey: 'Uploaded documents',
                          length: task.taskAttachments?.length || 0,
                        })}
                        <Accordion.Collapse eventKey="uploaded-documents">
                          <ul className="todo-list user-select-none">
                            {task.taskAttachments?.map?.((taskAttachment: Tasks.TaskAttachment) => (
                              <li key={taskAttachment.id} className="d-flex flex-column">
                                <a
                                  // className="text"
                                  href={taskAttachment.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {taskAttachment.name}
                                </a>

                                {taskAttachment.description ? <small>{taskAttachment.description}</small> : ''}
                              </li>
                            ))}
                          </ul>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="mb-0">
                        {renderAccordionToggle({
                          eventKey: 'responsible-users',
                          titleKey: 'Responsible users',
                          length: task.responsibleUsers?.length || 0,
                        })}
                        <Accordion.Collapse eventKey="responsible-users">
                          <ul className="todo-list">
                            {task.responsibleUsers.map(user => (
                              <li key={user.fullName} className="d-flex align-items-center">
                                <span className="text">
                                  {user.fullName} - {user.email}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="mb-0">
                        {renderAccordionToggle({
                          eventKey: 'assigned-users',
                          titleKey: 'Assigned users',
                          length: task.assignedUsers?.length || 0,
                        })}
                        <Accordion.Collapse eventKey="assigned-users">
                          <ul className="todo-list">
                            {task.assignedUsers.map(user => (
                              <li key={user.fullName} className="d-flex align-items-center">
                                <span className="text">
                                  {user.fullName} - {user.email}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="mb-0">
                        {renderAccordionToggle({
                          eventKey: 'erp-registered-hours',
                          titleKey: 'ERP registered hours',
                          length: task.usersLogs?.length
                            ? task.usersLogs.reduce((acc, item) => acc + item.registeredTime, 0)
                            : 0,
                        })}
                        <Accordion.Collapse eventKey="erp-registered-hours">
                          <ul className="todo-list">
                            <ul className="todo-list">
                              {task.usersLogs.map((user, index: number) => (
                                <li key={index} className="d-flex align-items-center">
                                  <span className="text">
                                    {user.registeredTime} - {user.fullName} - {user.email}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </ul>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                  <Col md={5}>
                    <TaskDetailsMeta task={task} />
                  </Col>
                </Row>
                <Tabs defaultActiveKey="comments" mountOnEnter>
                  <Tab eventKey="comments" title={getIntl('Comments')} className="mt-4">
                    <TaskDetailsComments taskId={id} />
                  </Tab>
                  {task.taskNumber && (
                    <Tab eventKey="hardware" title={getIntl('Hardware')} className="mt-4">
                      <TasksHardwareTable taskId={id} />
                    </Tab>
                  )}
                  <Tab eventKey="photo" title={`${getIntl('Answers photos')} (${images.length})`} className="mt-4">
                    <Gallery images={images} />
                  </Tab>

                  <Tab
                    eventKey="users_photo"
                    title={`${getIntl('Users photos')} (${task.usersAttachments.length})`}
                    className="mt-4"
                  >
                    <Row>
                      <Col xs={12}>
                        <Gallery
                          images={task.usersAttachments}
                          enableImageSelection
                          onSelectImage={handleSelectImage}
                        />
                      </Col>
                      {Boolean(task.usersAttachments.length) && (
                        <Col xs={12} className="text-right">
                          <Button
                            className="mt-3"
                            labelKey="Delete photos"
                            icon={<IconDelete />}
                            onClick={handleDeleteButtonClick}
                            variant="danger"
                            size="large"
                          />
                        </Col>
                      )}
                    </Row>
                  </Tab>
                </Tabs>
              </>
            )}
          </div>
        </StyledContainer>
      </DataTableTemplate>
      <TasksModal hideButton />
    </>
  );
};

const StyledContainer = styled.div`
  ${props => `
    padding: ${props.theme.paddings.primary};
    background: ${props.theme.colors.grey25};
    border-top: 1px solid ${props.theme.colors.grey100};
    display: flex;
    flex-grow: 1;

    > div {
      background: ${props.theme.colors.white};
      padding: ${props.theme.paddings.primary};
      border: 1px solid ${props.theme.colors.grey100};
      flex-grow: 1;
    }`}
`;

export default TaskDetails;
